import { delMany } from 'idb-keyval'
import { YMS_SOURCE_SYSTEM } from '@yms/common/constants'

import { prefetchWorker } from '@mobile/prefetch/prefetch'
import { API_BASE_URL, API_SUBSCRIPTION_KEY } from '@mobile/App.env'

import { client } from '../api/configuration'
import { userManager } from './userManager'

userManager.events.addUserLoaded(async user => {
  console.info('[auth] User loaded', user)
  client.setSecurityData(user.access_token)
  prefetchWorker.postMessage({
    type: 'setup',
    payload: {
      baseUrl: API_BASE_URL,
      subscriptionKey: API_SUBSCRIPTION_KEY,
      sourceSystem: YMS_SOURCE_SYSTEM,
      authorization: user.access_token
    }
  })
})

userManager.events.addAccessTokenExpiring(() => {
  console.info('[auth] Access token expiring')
})

userManager.events.addUserUnloaded(() => {
  client.setSecurityData(null)
  prefetchWorker.postMessage({ type: 'unload' })
})

userManager.events.addSilentRenewError(error => {
  console.info('[auth] Auth error', error)
})

userManager.events.addAccessTokenExpired(async () => {
  await delMany(['handlerId', 'yardZoneIds'])
  await userManager.removeUser()
  window.location.href = '/auth'
})
