import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@katoennatie/hexagon/style.css'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import dayjs from 'dayjs'
import './main.css'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { attachLogger, createTelemetryService } from '@yms/common/logging'
import { setupDayjs } from '@yms/common/utils/setupDayjs'
import { getLocaleImport } from '@yms/common/utils/getLocaleImport'
import { YMS_SOURCE_SYSTEM } from '@yms/common/constants'

import App from './App'
import { client } from './api/configuration'
import { setupYupLocale } from './i18n/setupYupLocale'
import { AI_CONNECTION_STRING, AI_ENABLED, API_BASE_URL, API_SUBSCRIPTION_KEY } from './App.env'
import { userManager } from './auth/userManager'
import { prefetchWorker } from './prefetch/prefetch'

import './auth/userManager.events'

setupDayjs()
setupYupLocale()

async function bootstrap() {
  document.documentElement.setAttribute('scale', 'large')

  const user = await userManager.getUser()
  if (user) {
    client.setSecurityData(user.access_token)
    prefetchWorker.postMessage({
      type: 'setup',
      payload: {
        baseUrl: API_BASE_URL,
        subscriptionKey: API_SUBSCRIPTION_KEY,
        sourceSystem: YMS_SOURCE_SYSTEM,
        authorization: user.access_token
      }
    })
  }

  // Ensure browser locale is loaded as soon as possible
  const locale = await getLocaleImport(window.navigator.language)
  dayjs.locale(locale.name)

  const application = (
    <StrictMode>
      <App />
    </StrictMode>
  )

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

  if (AI_ENABLED) {
    const { reactPlugin, appInsights } = createTelemetryService(AI_CONNECTION_STRING, AI_ENABLED)
    attachLogger(appInsights)

    const ApplicationWithAI = withAITracking(reactPlugin, () => application, 'AppAI')
    root.render(<ApplicationWithAI />)
  } else {
    root.render(application)
  }
}

bootstrap()
