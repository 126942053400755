export const prefetchWorker = new Worker(new URL('./prefetch.worker.ts', import.meta.url), {
  type: 'module'
})

export const prefetch = {
  stop: () => {
    prefetchWorker.postMessage({ type: 'stop' })
  },
  stopRefetch: () => {
    prefetchWorker.postMessage({ type: 'stopRefetch' })
  }
}
